import { React, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
// import ProfileTest from '../components/ProfileTest';
import CastingDetailItem from './CastingDetailItem';

function CastingListItems() {
  const { id } = useParams();
  console.log(id)
  const [castingitem, setCastingItem] = useState([]);

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id_casting: id })
  };

  useEffect(() => {
    fetch("/react/readCastingCompatibleInfo.php", options)
      .then(res => res.json())
      .then(
        (result) => {
          setCastingItem(result.castings);
         
        },
        (error) => {

        }
      )
  }, [])

  return (

    <div>
      <div >
        {castingitem.map(element => (

          <CastingDetailItem key={element.id} {...element} />

        ))}
      </div> 
    </div>
  )
}

export default CastingListItems