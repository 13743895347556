import React from 'react';
import Slider from '../components/Main/Slider';
import ApiTest from '../components/FetchingAndRenderingData/users/ApiTest';
import CastingsList from '../components/FetchingAndRenderingData/castings/CastingsList';
import Login from '../components/Auth/Login';
import CastingsTableList from '../components/FetchingAndRenderingData/castings/CastingsTableList';
import UserUploads from '../components/FetchingAndRenderingData/users/UserUploads'
import LoginB from '../components/Auth/LoginB';
import Basic from '../components/FetchingAndRenderingData/users/Dropzone';
import Image from '../components/FetchingAndRenderingData/users/Image';
import ImageFile from '../components/FetchingAndRenderingData/users/ImageFile';
import FacebookRegister from '../components/Auth/FacebookRegister';
import GoogleLogins from '../components/Auth/GoogleLogin';
import RegisterGoogle from '../components/Auth/RegisterGoogle';

function Home() {
    return (
        <div>
            <Slider />
            <CastingsTableList />
            <ApiTest />
        </div>
    )
}

export default Home
   