import { React } from 'react'
import { useParams } from 'react-router-dom';

function UserProfileByid ({ img, name, city, premium, surname }) {

    const { iduser } = useParams();
    console.log(`is id param ${iduser}`)


    return (

        <div className="wrapper">
       <div className="user-profile-card">
            <div className="profile-avatar"><img className="img" alt="img" src={`http://es.yatecasting.com/thumbResize.php?w=602&h=379&f=${img}`} /></div>
            <div className="user-bio">
            <p>Nombre: {name}</p>
                    <p>Surname: {surname}</p>
                    <p>Id usuario: {iduser}</p>
                    <p>Ciudad: {city}</p>
                    <p>Premium: {premium === "1" ? 'si' : 'no'}</p>
            </div>
        </div>
     
    </div>


    )
}


export default UserProfileByid