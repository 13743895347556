import CastingsList from '../components/FetchingAndRenderingData/castings/CastingsList';

function Castings() {
    return (
        <div>
            
            <CastingsList />    
          
        </div>
    )
}

export default Castings
   