import React, {useState, useContext} from 'react';
import {useDropzone} from 'react-dropzone';
import { AppContext } from "../../../Context/Provider";
import { sha256, sha224 } from 'js-sha256';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

function Basic(props) {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const [user, setUser] = useContext(AppContext);
 const [form, setForm] = useState({ fileimg: "" });
 const [url, setUrl] = useState("")

 const [message, setMessage] = useState("");
 const [file, setFile] = useState(
   );



  const hasUser = Object.keys(user).length === 0 ? false : true;
  console.log(hasUser);

  const loggedUser = hasUser === true ? user[0].iduser : 0;

  console.log(loggedUser);
// }



const parametrosImg = {
  method: "POST",
 
 
};
console.log(user)

const fileToUpload = () => {
  return file
}

async function uploadFile(fileToUpload) {
  fetch('/react/uploadUserFileBase64.php', {
    ...parametrosImg,

    body: JSON.stringify({

      // email: "elopez@dowhilestudio.com",
      id: user[0].iduser,
      fileimg: "",
      shaimg: "",
      base64file: base64_encode(file),
    }),
  })
    .then(response => response.json())
    .then(success => {
     console.log(success.message)
     setMessage(success.message);
     console.log(success.message)
     setUrl(success.fileimg)
     console.log(success.fileimg)
 
     console.log("fetched")
    })
    .catch(error => console.log(error)
  );
}

async function fetchFiles(fileToUpload) {
  console.log(file)
  try {
    const result = await fetch(
      "/react/uploadUserFile.php",

      {
        ...parametrosImg,

        body: JSON.stringify({
   
          email: user[0].email,
          id: user[0].iduser,
          fileimg: sha256(file.fileimg),
          shaimg: "",
          base64file: "",
         
        }),
      }
    );

    setMessage(result.message);
    setUrl(result.fileimg)
    console.log(url)
    console.log(result.message)
    console.log("fetched")

    return result;
  } catch (err) {
    console.log("err", err);

    throw err;
  }
}
const handleInputChange = (e) => {
  const image = e.target.files[0];
  // /* console.log(image); */
  // const uploadTask = storage.ref(${image.name}).put(image);

  setFile(image);
  
}


 console.log(parametrosImg)

 const handleSubmit = async (e) => {
   e.preventDefault()
  console.log("click")
 const fecthingFile = await uploadFile()
  console.log("message")

}

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} 
        value={file}/>
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside>
      <input
          className="btn-dark input-btn "
          type="submit"
          value="Subir archivo"
          onClick={handleSubmit}
        />
    </section>
  );
}

export default Basic