import { React, useContext, useEffect } from "react";
import Login from "../components/Auth/Login";
import DeleteUser from "../components/Auth/DeleteUser";
import { AppContext } from "../Context/Provider";
import Image from "../components/FetchingAndRenderingData/users/Image";
import ImageFile from "../components/FetchingAndRenderingData/users/ImageFile";

function Account() {
  const [user, setUser] = useContext(AppContext);
  console.log(user);
  const hasUser = user.length > 0;

  return (
    <>
    {hasUser ? 
    <div className="wrapper">
      <div className="my-account">
        <p className="section-title">Mi cuenta</p>

         {hasUser
          ? user.map((currentUser) => (
              <div key={currentUser.iduser} className="user-profile-card">
                <div className="profile-avatar">
                  <img
                    className="img"
                    alt="img"
                    src={`http://es.yatecasting.com/thumbResize.php?w=602&h=379&f=${currentUser.img}`}
                  />
                </div>
                
                <div className="user-bio">
                  <p>Nombre: {currentUser.name}</p>
                  <p>Surname: {currentUser.surname}</p>
                  <p>Id usuario: {currentUser.iduser}</p>
                  <p>Ciudad: {currentUser.city}</p>
                  <p>Premium: {currentUser.premium === "1" ? "si" : "no"}</p>
                </div>
                <img
                    className="img-uploaded"
                    alt="img"
                    src={`http://yatecasting.com/react/uploadsdemo/${currentUser.iduser}.jpg`}
                  />
              </div>
              
            ))
          : ""} 
      </div>
       <ImageFile />
            <Image />
      <DeleteUser />
       
    </div>
    
     : <Login />}
     

     </>
  );
}

export default Account;
