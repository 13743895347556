import React from 'react'
import FacebookRegister from '../components/Auth/FacebookRegister'
import RegisterGoogle from '../components/Auth/RegisterGoogle'
import Registration from '../components/Auth/Registration'

const RegisterUser = () => {
    return (
        <div>
            <Registration />
            <RegisterGoogle />
            <FacebookRegister />
        </div>
    )
}

export default RegisterUser
