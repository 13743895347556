import React from 'react';


function Slider() {
    return (
        
        <div className="slider-home">
            <p className="slider-text">Castings para actores, modelos, azafatas y nuevos talentos</p>
        </div>
    )
}

export default Slider
