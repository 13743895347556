import { React, useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "../../Context/Provider";
import md5 from "md5";
import { useHistory } from "react-router";
import FacebookLogin from "react-facebook-login";
import GoogleLogins from "./GoogleLogin";
import LoginB from "./LoginB";

const Login = () => {
  const [form, setForm] = useState({ username: "", password: "" });
  
  const [formError, setFormError] = useState("");

  const [error, setError] = useState("");

  const [success, setSuccess] = useState("");

  const history = useHistory();

  const [user, setUser] = useContext(AppContext);

  const [loggedUser, setLoggedUser] = useState(null);

  const [facebookData, setFacebookData] = useState({
    username: "",
    token: "",
    faceid: "",
  });
  // console.log(`user fetched ${user}`);

  const handleLogout = () => {
    setLoggedUser(null);
    window.localStorage.removeItem("loggedUser");
    console.log(loggedState.current);
    setUser([]);
  };

  const successState = useRef(0);
  successState.current = success;

  const loggedState = useRef();
  loggedState.current = loggedUser;

  useEffect(
    function () {
      setTimeout(function () {
        console.log(success);
        console.log(`Useeffect con ref ${successState.current}`);
      }, 1000);
    },
    [success]
  );

  const parametrosFacebook = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //podria ser el form
      username: facebookData.username,
      token: facebookData.token,
      faceid: facebookData.faceid,
    }),
  };

  const parametros = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //podria ser el form
      username: form.username,
      password: md5(form.password),
    }),
  };

  async function fetchUser() {
    console.log(parametros);

    if (form.username && form.password !== "") {
      try {
        console.log(parametros);
        const response = await fetch("/react/userLog.php", parametros);
        const result = await response.json();
        setError(result.message);
        setSuccess(result.success);
        // setSuccess(prevSuccess => ([...prevSuccess, ...success]));
        setUser(result.users);
        // const successful = result.success === 1 ?  setSuccess(result.success) : console.log("fetch con 0")
        window.localStorage.setItem("loggedUser", JSON.stringify(result.users));
        setLoggedUser(result.users);
      } catch (err) {
        throw err;
        console.log(err);
      }
    }
  }

  async function fetchFacebookUser() {
    console.log(parametrosFacebook);
    try {
      console.log(parametrosFacebook);
      console.log(`parametros en facebook fetch ${parametrosFacebook}`);
      const responseFacebook = await fetch(
        "/react/userLogFace.php",
        parametrosFacebook
      );
      const resultFacebook = await responseFacebook.json();
      console.log("fetching facebook users");
      console.log(resultFacebook);
      setError(resultFacebook.message);
      setSuccess(resultFacebook.success);
      // setSuccess(prevSuccess => ([...prevSuccess, ...success]));
      setUser(resultFacebook.users);
      // const successful = resultFacebook.success === 1 ?  setSuccess(resultFacebook.success) : console.log("fetch con 0")
      window.localStorage.setItem(
        "loggedUser",
        JSON.stringify(resultFacebook.users)
      );
      setLoggedUser(resultFacebook.users);
    } catch (err) {
      throw err;
      console.log(err);
    }
  }

  useEffect(() => {
    const loggedUserJSON = window.localStorage.getItem("loggedUser");

    if (loggedUserJSON) {
      const usuario = JSON.parse(loggedUserJSON);
      setLoggedUser(usuario);
      console.log(loggedState);
    }
  }, []);

  // function validating() {
  //     if(success === 1) {
  //       return user
  //     }
  // }

  const hasUser = success === 1 ? true : false;
  console.log(`funcion valida has user ${hasUser}`);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    /*  console.log("handleInputChange -> name", name, value) */

    const newFormValues = {
      ...form,
      [name]: value,
    };
    /* console.log("handleInputChange -> newFormValues", newFormValues) */
    setForm(newFormValues);
  };

  console.log(`error del estado ${error}`);
  console.log(`success del estado ${success}`);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log("******click");
    const result = await fetchUser();
    console.log(result);
    setFormError("");
    console.log(`*****handleform después del fetch ${hasUser}`);
    console.log(`*error del estado dentro de la función ${error}`);
    console.log(`*success del estado dentro de la función ${success}`);
    console.log(`*user dentro de la función ${user}`);
    console.log(
      `*Useeffect con ref dentro de la función ${successState.current}`
    );

    if (!form.username || !form.password) {
      setFormError("Email y contraseña obligatorios");
      return;
    }

    if (successState.current === 0) {
      setFormError("Email y/o contraseña incorrectos");
      console.log("login NO OK");
      console.log(success);
      return;
    } else {
      console.log(success);
      console.log("login OK");
      console.log(user);
      history.push("/mi-cuenta");
    }
  };

  const responseFacebook = (respuesta) => {
    console.log(respuesta);

    setFacebookData({
      username: respuesta.email,
      token: respuesta.accessToken,
      faceid: respuesta.userID,
    });
    handleFacebookSubmit();
  };

  const handleFacebookSubmit = async () => {
    const faceFetch = await fetchFacebookUser();

    if (successState.current === 0) {
      console.log("login NO OK");
      console.log(success);
      return;
    } else {
      console.log(success);
      console.log("login OK");
      console.log(user);
      history.push("/mi-cuenta");
    }
  };
  console.log(facebookData);
  //  console.log(form);
  return (
    <div className="login">
      <h1>Iniciar sesión</h1>
      <form onSubmit={handleFormSubmit}>
        <div className="login-form">
          <label className="input-label" htmlFor="username"></label>
          <input
            className={`${formError ? "error" : ""} ${"login-input"}`}
            placeholder="Email"
            id="username"
            name="username"
            type="text"
            value={form.username}
            error={formError}
            onChange={handleInputChange}
          />
          {formError && <span className="error-text">{formError}</span>}
        </div>
        <div className="login-form">
          <label className="input-label" htmlFor="password"></label>
          <input
            className={`${
              formError ? "error" : ""
            } ${"login-input"} ${"input-box-password"}`}
            placeholder="Contraseña"
            id="password"
            name="password"
            type="password"
            value={form.password}
            error={formError}
            onChange={handleInputChange}
          />
          {formError !== "" && <span className="error-text">{formError}</span>}
        </div>
        <input
          className="btn-dark input-btn "
          type="submit"
          value="Entrar"
          onSubmit={handleFormSubmit}
        />
        {/* {loggedUser ? (
          <button type="submit" className="btn-light" onClick={handleLogout}>
            Logout
          </button>
        ) : (
          ""
        )} */}
      </form>
      <br />
      {/* <FacebookLogin
        appId="206374764757325"
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        textButton="Iniciar sesión con Facebook"
        icon="fa-facebook"
        // onClick={handleFacebookSubmit}
      /> */}
      <GoogleLogins />
      <LoginB />
    </div>
  );
};

export default Login;
