import React, {useState, useContext} from 'react';
import { AppContext } from "../../../Context/Provider";

const Image = () => {
    const [baseImage, setBaseImage] = useState("");
    const [user, setUser] = useContext(AppContext);
    
 const [message, setMessage] = useState("");
 const [url, setUrl] = useState("")

    const uploadImage = async (e) => {
      const file = e.target.files[0];
      const base64 = await convertBase64(file);
      setBaseImage(base64);
    };
  console.log(baseImage)
    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
  
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
  
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };
    const parametrosImg = {
        method: "POST",
       
       
      };

    const fileToUpload = () => {
        return baseImage
      }

    async function uploadFile(fileToUpload) {
        fetch('/react/uploadUserFileBase64.php', {
          ...parametrosImg,
      
          body: JSON.stringify({
      
            // email: "elopez@dowhilestudio.com",
            id: user[0].iduser,
            fileimg: "baseimg",
            shaimg: "baseImage",
            base64file: baseImage.replace("data:image/jpeg;base64,", "")
          }),
        })
          .then(response => response.json())
          .then(success => {
           console.log(success.message)
           setMessage(success.message);
           console.log(success.message)
           setUrl(success.fileimg)
           console.log(success.fileimg)
       
           console.log("fetched")
          })
          .catch(error => console.log(error)
        );
      }

      
      const handleSubmit = async (e) => {
        e.preventDefault()
       console.log("click")
      const fecthingFile = await uploadFile()
       console.log("message")
     
     }
  
    return (
      <div className="App">
        <input
          type="file"
          onChange={(e) => {
            uploadImage(e);
          }}
        />
        <br></br>
        <img src={url} height="200px" />
        <input
          className="btn-dark input-btn "
          type="submit"
          value="Subir archivo"
          onClick={handleSubmit}
        />
      </div>
    );
  }


export default Image
