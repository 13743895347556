import { React, useEffect, useState } from 'react'
import {Link} from 'react-router-dom';

function CastingItems({castings_type , id, name, paid, publicated_from, publicated_to, country, region, city, activated, description, num_candidates, visits,link,logo }) {

 
    return (
     
            <div className="casting">
                <div className="casting-content">
                <div className="casting-top-header">
                    <p>Fecha Publicación: {publicated_from}</p>
                    <p>Fin: {publicated_to}</p>
                </div>
                <div className="casting-header">
                    <p>🎬: {castings_type}</p>
                    <p>💰: {paid === "0" ? "✅" : "❌"}</p>
                </div>
                <div className="casting-bottom-header">
                    <p>🚩{ city}, {region}</p>
                    <p>👤 {num_candidates}</p>
                </div>
                <div className="casting-main">
                <div
                dangerouslySetInnerHTML={{
                    __html: name
                }}></div>
                </div>
                </div>
                <div className="casting-bottom">
                <Link to={`/casting/${id}`}><button className="btn-text">Ver casting</button></Link>
    
                </div>
            </div>
        

    )
}


export default CastingItems