import { React, useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom';
// import ProfileTest from '../components/ProfileTest';
import CastingItems from './CastingItems';
import Loader from '../../Main/Loader';
import CastingsTable from './CastingsTable';

function CastingsList() {
  
  const [castings, setCastings] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const parametros = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ page: 0 })
  };

 
  useEffect(() => {
    fetch("/react/readAllCastings.php", parametros)
      .then(res => res.json())
      .then(
        (result) => {
          setCastings(result.castings);
          console.log(`result ${result}`)
          setIsLoaded(true);
         
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])
  if (error) {
    return <div>Error: {error.message}</div>;

  } else if (!isLoaded) {
    return <div><p className="loading-text">Buscando últimos Castings...</p> <Loader /></div>;
  } else {
  // console.log(`findme ${result}`)
  return (
    <>
    <div className="wrapper">
      <h1>Últimos castings publicados</h1>
      <div className="castings" >
        {castings.map(element => (
          <CastingItems key={element.id} {...element} />
         
        ))}
         
      </div>
    </div>
    </>
  )
}
}
export default CastingsList
