import { React, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ProfileTest from './UserProfileByid';

function UserProfile() {
  const { iduser } = useParams();
  console.log(iduser)
  const [filtered, setFilter] = useState([]);
  // const params = {
  //   id_user: iduser,

  // };
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    //body: "id_user: 305467"
    body: JSON.stringify({ id_user: iduser })
  };

  useEffect(() => {
    fetch("/react/readCandidateInfo.php", options)
      .then(res => res.json())
      .then(
        (result) => {
          setFilter(result.users);
         
        },
        (error) => {

        }
      )
  }, [])

  // console.log(`findme ${result}`)
  return (

    <div>
      <div >
        {filtered.map(element => (

          <ProfileTest key={element.iduser} {...element} />

        ))}
      </div>
    </div>
  )
}

export default UserProfile
