import { React, useEffect, useState, useContext, useRef } from "react";

import { AppContext } from "../../Context/Provider";

import md5 from "md5";

import { useHistory } from "react-router";

import FacebookLogin from "react-facebook-login";

const FacebookRegister = () => {
  const [form, setForm] = useState({ username: "", password: "" });

  const [formError, setFormError] = useState("");

  const [error, setError] = useState("");

  const [success, setSuccess] = useState("");

  const history = useHistory();

  const [user, setUser] = useContext(AppContext);

  const [loggedUser, setLoggedUser] = useState(null);

  const handleLogout = () => {
    setLoggedUser(null);

    window.localStorage.removeItem("loggedUser");

    setUser([]);
  };

  const parametrosFacebook = {
    method: "POST",

    headers: { "Content-Type": "application/json" },
  };

  const parametros = {
    method: "POST",

    headers: { "Content-Type": "application/json" },

    body: JSON.stringify({
      //podria ser el form

      username: form.username,

      password: md5(form.password),
    }),
  };

  

  async function fetchUser() {
    if (form.username && form.password !== "") {
      try {
        console.log(parametros);

        const response = await fetch("/react/userLog.php", parametros);

        const result = await response.json();

        setError(result.message);

        setSuccess(result.success);

        // setSuccess(prevSuccess => ([...prevSuccess, ...success]));

        setUser(result.users);

        // const successful = result.success === 1 ? setSuccess(result.success) : console.log("fetch con 0")

        window.localStorage.setItem("loggedUser", JSON.stringify(result.users));

        setLoggedUser(result.users);
      } catch (err) {
        throw err;

        console.log(err);
      }
    }
  }

  async function fetchFacebookUser(facebookData) {

    console.log(facebookData)
    try {
      const responseFacebook = await fetch(
        "/react/userRegistrationFace.php",

        {
          ...parametrosFacebook,

          body: JSON.stringify({
            //podria ser el form

            email: "jcfnlfdlck_1629892041@tfbnw.net", //facebookData.email,
            
            confirmemail: "jcfnlfdlck_1629892041@tfbnw.net", //facebookData.email,

            password: "YATE1234",

            token: facebookData.accessToken,

            faceid: facebookData.userID,
          }),
        }
      );

      const resultFacebook = await responseFacebook.json();
     console.log(resultFacebook.faceid)
     console.log(resultFacebook.message)
     console.log(resultFacebook)

      return resultFacebook;
    } catch (err) {
      console.log("err", err);

      throw err;
    }
  }


  const handleInputChange = (event) => {
    const { name, value } = event.target;

    /* console.log("handleInputChange -> name", name, value) */

    const newFormValues = {
      ...form,

      [name]: value,
    };

    /* console.log("handleInputChange -> newFormValues", newFormValues) */

    setForm(newFormValues);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const result = await fetchUser();

    setFormError("");

    if (!form.username || !form.password) {
      setFormError("Email y contraseña obligatorios");

      return;
    }

    // if (successState.current === 0) {

    // setFormError("Email y/o contraseña incorrectos");

    // console.log("login NO OK");

    // console.log(success)

    // return

    // }
    else {
      console.log(success);

      console.log("login OK");

      console.log(user);

      history.push("/mi-cuenta");
    }
  };

  const responseFacebook = (respuesta) => {
    handleFacebookSubmit(respuesta);
  };

  const handleFacebookSubmit = async (FbResponse) => {
    const faceFetch = await fetchFacebookUser(FbResponse);

    if (!!faceFetch.success) history.push("/mi-cuenta");
  };

  return (
    <div className="login">
      <br />

      <FacebookLogin
        appId="206374764757325"
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        textButton="Registrarse con Facebook"
        icon="fa-facebook"

        // onClick={handleFacebookSubmit}
      />
    </div>
  );
};

export default FacebookRegister;
