import {createContext, useState, useEffect } from 'react';

export default ({ children }) =>{
    const [user, setUser] = useState([]); //{} facebook

    // useEffect(() => {
    //     localStorage.setItem('loggedUser', JSON.stringify(user));
    //   }, [user]);

      useEffect(() => {
        const storage = window.localStorage.getItem('loggedUser')
       
       if (storage) {
          const usuario = JSON.parse(storage)
          setUser(usuario)
        }
        }, [])

    return (            
            <AppContext.Provider value={[user, setUser]}>
                {children}
            </AppContext.Provider>  
    );
}

export const AppContext = createContext();
