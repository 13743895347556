import React from 'react'


function CastingDetailItem({castings_type , id, name, paid, publicated_from, publicated_to, country, region, city, activated, description, num_candidates, visits,link,logo }) {
    return (
       
            <div>
     
        <div className="casting-detail">
                <div className="casting-content">
                <div className="casting-top-header">
                    <p>Fecha Publicación: {publicated_from}</p>
                    <p>Fin: {publicated_to}</p>
                </div>
                <div className="casting-header">
                    <p>🎬: {castings_type}</p>
                    <p>💰: {paid === "0" ? "✅" : "❌"}</p>
                </div>
                <div className="casting-bottom-header">
                    <p>🚩{ city}, {region}</p>
                    <p>👤 {num_candidates}</p>
                </div>
                <div className="casting-main">
                <div
                dangerouslySetInnerHTML={{
                    __html: description
                }}></div>
                </div>
                </div>
                <div className="button btn-dark">Me apunto</div>
            </div>
  
    </div>
        
    )
}

export default CastingDetailItem
