
import { React, useEffect, useState, useContext, useRef } from "react";
import GoogleLogin from "react-google-login";
import { AppContext } from "../../Context/Provider";
import { useHistory } from "react-router";


const RegisterGoogle = () => {


    const [user, setUser] = useContext(AppContext);

    const [loggedUser, setLoggedUser] = useState(null);

    const [success, setSuccess] = useState("");

    const history = useHistory();
    
    const parametrosgoogle = {
        method: "POST",
    
        headers: { "Content-Type": "application/json" },
      };

    const googleId = "460600040229-e111ccv2qvv7g4iffnpip8h2m7f9v2hc.apps.googleusercontent.com";

    async function fetchGoogleUser(googleData) {

        console.log(googleData)
        console.log("fetching")
        console.log(googleData.profileObj.email)
        console.log(googleData.tokenObj.access_token)
        console.log(googleData.googleId)

        try {
          const responseGoogle = await fetch(
            "/react/userRegistrationGoog.php", 
            {
              ...parametrosgoogle,
    
              body: JSON.stringify({
               
                email: googleData.profileObj.email,
                confirmemail: googleData.profileObj.email,
                password: "12345",
                token: googleData.tokenObj.access_token,
                googid: googleData.googleId,
                
              }),
            }
          );
     
          const resultGoogle = await responseGoogle.json();
          console.log("fetched")
    
        //   window.localStorage.setItem(
        //     "loggedUser",
        //     JSON.stringify(resultGoogle.users)
        //   );
        //  setUser(resultGoogle.users);
       
          console.log( resultGoogle)
          return resultGoogle;
        } catch (err) {
          console.log("err", err);
    
          throw err;
        }
      }
    
    //   useEffect(() => {
    //     const loggedUserJSON = window.localStorage.getItem("loggedUser");
    
    //     if (loggedUserJSON) {
    //       const usuario = JSON.parse(loggedUserJSON);
    
    //       setLoggedUser(usuario);
    //     }
    //   }, []);


    const responseGoogle= (respuesta) => {
        handleGoogleSubmit(respuesta);
      };

      const handleGoogleSubmit = async (GoogleResponse) => {
        const googleFetch = await fetchGoogleUser(GoogleResponse);
    
        if (!!googleFetch.success) history.push("/mi-cuenta");
      };

    return (
        <div  className="login">
           
      <GoogleLogin
        clientId={googleId}
        buttonText="Register with Google"
        onSuccess={responseGoogle}
        // onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
        onClick={handleGoogleSubmit}
      />

      
    </div>
        
    )
}

export default RegisterGoogle
