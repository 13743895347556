import '../src/sass/App.scss';
import Home from "./pages/Home";
import { BrowserRouter as Router, Route,	Switch } from 'react-router-dom';

import ProfileByid from './components/ExplainingReact/ProfileByid';
import UserProfile from './components/FetchingAndRenderingData/users/UserProfile';
import Castings from './pages/Castings'
import CastingDetail from './components/FetchingAndRenderingData/castings/CastingDetail'
import Account from './pages/Account';
import RegisterUser from './pages/Register';
import { useState, useEffect } from 'react';

import Layout from './components/Layouts/Layout';


function App() {

//   const [user, setUser] = useState();

//   useEffect(() => {
//     const loggedInUser = localStorage.getItem("loggedUser");
//     if (loggedInUser) {
//       const foundUser = JSON.parse(loggedInUser);
//       setUser(foundUser);
//     }
//   }, []);

// console.log(user)
  return (

    <Router>
      <Layout>
      <Switch>
      <Route exact path='/' component={Home}></Route>
      <Route exact path='/castings' component={Castings}></Route>
      {/* <Route exact path='/premium-profile' component={Premiumprofile}></Route> */}
      <Route exact path='/profile/:id' component={ProfileByid}></Route>
      <Route exact path='/profiles/:iduser' component={UserProfile}></Route>
      <Route exact path='/casting/:id' component={CastingDetail}></Route>
      <Route exact path='/mi-cuenta' component={Account}></Route>
      <Route exact path='/registro' component={RegisterUser}></Route>
    </Switch>
    </ Layout >
  </Router>
 
  );
}

export default App;
