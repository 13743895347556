import { React, useEffect, useState } from 'react'

import CardApi from './CardApi';

function ApiTest() {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);


  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    //body: "id_user: 305467"
    body: JSON.stringify({ page: 15, id_country: 206 })
  };

  useEffect(() => {
    fetch("/react/readAllCandidates.php", options)
      .then(res => res.json())

      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.users);
         //console.log(result)
          //console.log(`son los ${result}`)
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;

  } else if (!isLoaded) {
    return <div>Cargando usuarios...</div>;
  } else {
    return (
      // <ul>
      //  {items.map(item => (
      //     <li key={item.iduser}>
      //       {item.name} {item.city} <img className= "img" src={`http://es.yatecasting.com/thumbResize.php?w=602&h=379&f=${item.img}`} />
      //     </li>
      //   ))} 
      // </ul>
      <div className="wrapper">
        <h1>Usuarios</h1>

        <div className="card-list">
          {
            items.map((item) => <CardApi key={item.iduser} {...item} />
            )}


        </div>
      </div>
    );
  }
}

export default ApiTest

