
import { React, useContext, useState } from "react";
import { AppContext } from "../../Context/Provider";
import { useHistory } from "react-router";

const DeleteUser = () => {

    const [user, setUser] = useContext(AppContext);
    console.log(user);
    const [form, setForm] = useState({ email: ""});
    const [formError, setFormError] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const history = useHistory();

    const hasUser = Object.keys(user).length === 0 ? false : true;
  console.log(hasUser);

    const loggedUser = hasUser === true ? user[0].iduser : 0;

    console.log(loggedUser)
  


    const parametros = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          //podria ser el form
          email: form.email,  
        id: loggedUser, 
        }),
      };

      async function deleteTheUser() {
        
          try{
        
            const response = await fetch("/react/deleteUser.php", parametros)
            const result = await response.json();
    
            console.log(result)
            setError(result.message);
            setSuccess(result.success);
            console.log(result.message)
            console.log(result.success)
          }
          catch(err) {
            throw err;
            console.log(err);
          }
      
      }

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        /*  console.log("handleInputChange -> name", name, value) */
        
        const newFormValues = {
          ...form,
          [name]: value,
        };
        /* console.log("handleInputChange -> newFormValues", newFormValues) */
        setForm(newFormValues);
      };

      const deleteLoggedUser = async (event) => {
        event.preventDefault();
        const deleting = await deleteTheUser()
        
        setTimeout(function () {
            window.localStorage.removeItem('loggedUser')
            setUser([])
            setError("Usuario eliminado")
            history.push("/");
          }, 3000);
        
    }
    
    return (
        <div>
            {hasUser ? 
            <>
            <h5>Eliminar mi cuenta</h5>

            <input
            className={`${formError ? "error" : ""} ${"login-input"}`}
            placeholder="Introduce email "
            id="email"
            name="email"
            type="text"
            value={form.email}
            error={formError}
            onChange={handleInputChange}
          />
          <button className="btn-light" onClick={deleteLoggedUser}>Borrar mi cuenta</button>
          <div className={`${error === "Usuario eliminado" ? "deleted-message" :  ""} `}>
            <p>{error}</p>
          </div>
           </>
            : ""}
            
        </div>
    )
}

export default DeleteUser
