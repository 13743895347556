import { React, useEffect, useState, useContext, useRef} from "react";
import { AppContext } from "../../Context/Provider";
import md5 from "md5";
import { useHistory } from "react-router";
import FacebookLogin from 'react-facebook-login';


const Registration = () => {
  const [form, setForm] = useState({ email: "", confirmemail: "", password: "" });
  const [formError, setFormError] = useState("");
  const [success, setSuccess] = useState("");
  const [user, setUser] = useState("");
  const [error, setError] = useState("");
  const successState = useRef(0);
  successState.current = success;

  const responseFacebook = (response) => {
    console.log(response);
  }
   
  useEffect(
    function () {
      setTimeout(function () {
        console.log(success);
        console.log(`Useeffect con ref ${successState.current}`);
      }, 1000);
    },
    [success]
  );

  const parametros = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //podria ser el form
      email: form.email,  
      confirmemail: form.confirmemail,  
      password: md5(form.password), 
    }),
  };


  async function registerUser() {
    
    if (form.email && form.confirmemail && form.password  !== "") {
      try{
        const response = await fetch("/react/userRegistration.php", parametros)
        const result = await response.json();
        setError(result.message);
        setSuccess(result.success);
      
       
       
      console.log(result.message)
      console.log(result.success)
      console.log(result.user)
      }
      catch(err) {
        throw err;
        console.log(err);
      }
  }
}



  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    
    const newFormValues = {
      ...form,
      [name]: value,
    };
    /* console.log("handleInputChange -> newFormValues", newFormValues) */
    setForm(newFormValues);
  };

    
    const handleFormSubmit = async (event) => {

    event.preventDefault();
   
    const result = await registerUser();

    if (!form.email || !form.password || !form.confirmemail) {
      setFormError("Email y contraseña obligatorios");
       return
     }

     if (form) {
       setFormError("Email y/o contraseña incorrectos");
       console.log("login NO OK");
       
       return
     } 
      else {
      
      }
  
  };

  return (
    <div className="login">
     <h1>Registro</h1>
      <form onSubmit={handleFormSubmit}>
      <div className="login-form">
          <label className="input-label" htmlFor="email"></label>
          <input
            className={`${formError ? "error" : ""} ${"login-input"}`}
            placeholder="Email"
            id="email"
            name="email"
            type="text"
            value={form.name}
            error={formError}
            onChange={handleInputChange}
          />
          {formError && <span className="error-text">{formError}</span>}
        </div>
        <div className="login-form">
          <label className="input-label" htmlFor="confirmemail"></label>
          <input
            className={`${formError ? "error" : ""} ${"login-input"}`}
            placeholder="Confirma email"
            id="confirmemail"
            name="confirmemail"
            type="text"
            value={form.username}
            error={formError}
            onChange={handleInputChange}
          />
          {formError && <span className="error-text">{formError}</span>}
        </div>
        <div className="login-form">
          <label className="input-label" htmlFor="password"></label>
          <input
            className={`${
              formError ? "error" : ""
            } ${"login-input"} ${"input-box-password"}`}
            placeholder="Contraseña"
            id="password"
            name="password"
            type="password"
            value={form.password}
            error={formError}
            onChange={handleInputChange}
          />
          {formError !== "" && <span className="error-text">{formError}</span>}
        </div>
        <input
          className="btn-dark input-btn "
          type="submit"
          value="Registrarme"
          onSubmit={handleFormSubmit}
        />
        
      </form>

      <br />
      {/* <FacebookLogin
        appId="206374764757325"
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        textButton="Registrarme con Facebook"
        icon="fa-facebook"
      /> */}

    </div>
  );
        }

export default Registration;
