import { React, useContext, useState, useEffect, useRef } from "react";
import Logo from "../../img/logo-yatecasting-bw.png";
import { Link } from "react-router-dom";
import { AppContext } from "../../Context/Provider";
import Login from "../Auth/Login";
import { useHistory } from "react-router";

function Header() {
  const [user, setUser] = useContext(AppContext); //context no guarda sesión
  const currentUser = user[0]
  const history = useHistory();
  const hasUser = Object.keys(user).length === 0 ? false : true; //testeo si el objeto tiene datos /1sr round de pruebas sin storage ni context
 
  let storage = window.localStorage.getItem('loggedUser')
  
  const [loggedUser, setLoggedUser] = useState('')

  const loggedState = useRef(window.localStorage.getItem('loggedUser'));
   loggedState.current = loggedUser;
  console.log(loggedUser)

  useEffect(() => {
    const storage = window.localStorage.getItem('loggedUser')
   
   if (storage) {
      const usuario = JSON.parse(storage)
      setLoggedUser(usuario)
      
    }
    }, [])
  
    const handleLogout = () => {
      setTimeout(function () {
        setLoggedUser("")
        window.localStorage.removeItem('loggedUser')
        setUser([])
        history.push("/");
      }, 500);
    }

  return (
    <div>
      <div className="header">
        <div className="header-logo">
          <Link to="/">
            <img className="logo" src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="nav">
          <ul>
            <Link to="/castings">
              <li className="nav-items">CASTINGS</li>
            </Link>
            <li className="nav-items">PREMIUM</li>
            <li className="nav-items">CANDIDATOS</li>
            <li className="nav-items">FORMACIÓN</li>
          </ul>
          <div className="btns">
          
          {currentUser ?
                // <div>
                //   {/* <p className="header-name">Hola, {}</p> */}
                //   <p className="header-name">Hola, {currentUser.name}</p> 
                // </div>
                <Link to="/mi-cuenta">
                <button className="btn-dark">MI CUENTA</button>
              </Link>
              
            : <Link to="/registro">
              <button className="btn-light">REGISTRO</button>
              </Link>
              }
            {/* {hasUser ? "" : <button className="btn-light">REGISTRO</button>} */}
            {hasUser ? //currentUser
                  <Link to="/mi-cuenta">
                  <button className="btn-light" onClick={handleLogout}>CERRAR SESIÓN</button>
                </Link>
              
            :   <Link to="/mi-cuenta">
            <button className="btn-dark">INCIAR SESIÓN</button>
          </Link>}
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
