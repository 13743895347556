import React, {useState, useContext} from 'react';
import { AppContext } from "../../../Context/Provider";

const ImageFile = () => {
    const [image, setImage] = useState("");
    const [user, setUser] = useContext(AppContext);
    
 const [message, setMessage] = useState("");
 const [url, setUrl] = useState("")

    const uploadImage = async (e) => {
      const file = e.target.files[0];
      
      setImage(file);
    };

    const fileToUpload = () => {
        return image
      
      }
      console.log(image)

    async function uploadFile(fileToUpload) {
      
        const formData = new FormData()
        formData.append('fileimg', image)
        formData.append("id", user[0].iduser);
        console.log(image)

        fetch('/react/uploadUserFile.php', {
            method: "POST",
            body: formData,
           
          })
          .then(response => response.json())
          .then(success => {
           console.log(success.message)
           setMessage(success.message);
           console.log(success.message)
           setUrl(success.fileimg)
           console.log(success.fileimg)
       
           console.log("fetched")
          })
          .catch(error => console.log(error)
        );
      }

      
      const handleSubmit = async (e) => {
        e.preventDefault()
       console.log("click")
      const fecthingFile = await uploadFile()
       console.log("message")
     
     }
  
    return (
      <div className="App">
        <input
          type="file"
          onChange={(e) => {
            uploadImage(e);
          }}
        />
        <br></br>
        <img src={url} height="200px" />
        <input
          className="btn-dark input-btn "
          type="submit"
          value="Subir archivo"
          onClick={handleSubmit}
        />
      </div>
    );
  }


export default ImageFile
