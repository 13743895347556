import React from 'react'

const Loader = () => {
    return (
                    
<div className="loaders">
  <div className="loader circle-loader-1"></div>
 
  </div>

    )
}

export default Loader
