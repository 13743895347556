//import React from 'react'
import {Link} from 'react-router-dom';


function CardApi({iduser, img, name, city, premium, surname }) {

 
    
    return (
            <div className="user-card">
                <div className="user-avatar"><img className= "img" alt="img" src={`http://es.yatecasting.com/thumbResize.php?w=602&h=379&f=${img}`} /></div>
                <div className="user-bio">
                <p>Nombre: {name}</p>
                    {/* <p>Nombre: {name} {surname}</p> */}
                    <p>Surname: {surname}</p>
                    <p>Ciudad: {city}</p>
                    <p>Premium: {premium === "1" ? 'si' : 'no'}</p>
                    <Link to={`/profiles/${iduser}`}><button className="btn-text">Ver perfil</button></Link>
                </div>
            </div>
    )
}

export default CardApi
