import { React } from 'react'
import { useParams } from 'react-router-dom';
import Header from '../Main/Header';
import Footer from '../Main/Footer';
import UserAvatar from '../../../src/img/user.jpg';
import UserAvatar2 from '../../../src/img/user2.jpg';
import UserAvatar3 from '../../../src/img/user3.jpg';
import UserAvatar4 from '../../../src/img/user4.jpg';
import UserAvatar5 from '../../../src/img/user5.jpg';


const ProfileByid = () => {

    const usersIds = [{
        id: 0,
        avatar: UserAvatar,
        name: 'Joan',
        lastName: 'Puig',
        estatura: '182cm',
        ojos: 'azules',
        pelo: 'castaño',
    }, 
    {
        id: 1,
        avatar: UserAvatar2,
        name: 'Pepe',
        lastName: 'Puig',
        estatura: '182cm',
        ojos: 'azules',
        pelo: 'castaño',
    },
    {
        id: 2,
        avatar: UserAvatar3,
        name: 'Jose',
        lastName: 'Puig',
        estatura: '182cm',
        ojos: 'azules',
        pelo: 'castaño',
    },
    {
        id: 3,
        avatar: UserAvatar4,
        name: 'Albert',
        lastName: 'Puig',
        estatura: '182cm',
        ojos: 'azules',
        pelo: 'castaño',
    },
    {
        id: 4,
        avatar: UserAvatar5,
        name: 'Ramon',
        lastName: 'Puig',
        estatura: '182cm',
        ojos: 'azules',
        pelo: 'castaño',
    },
    ] 
    const {id} = useParams();
    console.log(id)

    const findById = usersIds.find(element => element.id === parseInt(id, 10)
   
    )
  
  console.log(findById)

  const entries = Object.keys(findById);
  console.log(entries)
  console.log(`el id es ${id}`)


    return (
        <div>
            <Header />
                 <div className="user-profile-card">
               <div className="profile-avatar"><img src={findById.avatar} alt="avatar" /></div>
               <div className="user-bio">
                  <p>Nombre: {findById.name} </p>
                  <p>Estatura: {findById.estatura}</p>
                   <p>Ojos: {findById.ojos}</p>
                   <p>Pelo: {findById.pelo}</p>
               </div>
               </div> 
            <Footer />
        </div>
        
    )
}

export default ProfileByid

//    {usersIds.filter(userid => userid.id = {id} ).map(filteredId => ( 
//                  <div className="user-profile-card">
//                <div className="profile-avatar"><img src={filteredId.avatar} alt="avatar" /></div>
//                <div className="user-bio">
//                    <p>Nombre: {filteredId.name}</p>
//                    <p>Estatura: {filteredId.estatura}</p>
//                    <p>Ojos: {filteredId.ojos}</p>
//                    <p>Pelo: {filteredId.pelo}</p>
                  
//                </div>
//                </div> 
//                 ) )}